import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Header, Layout, PreviewCompatibleImage } from "../components";
import { CardWithButton, CardContainer } from "../ui";
import { Wrapper, Container } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/journal.jpg";

export const ActualitesPageTemplate = ({ title, posts }) => {
  return (
    <>
      <SEO title={title} />
      <Header title={title} bgImage={bgImage} fallback={linearGradients.actualites} />
      <Wrapper>
        <Container large>
          <CardContainer two>
            {posts.map(({ node: post }) => {
              const { id, frontmatter, fields, excerpt } = post;
              const image = frontmatter.thumbnail ? frontmatter.thumbnail : frontmatter.image;
              return (
                <CardWithButton
                  key={id}
                  date={frontmatter.date}
                  title={frontmatter.title}
                  text={excerpt}
                  link={fields.slug}>
                  <PreviewCompatibleImage
                    imageInfo={image}
                    style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                  />
                </CardWithButton>
              );
            })}
          </CardContainer>
        </Container>
      </Wrapper>
    </>
  );
};

ActualitesPageTemplate.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array,
};

const ActualitesPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ActualitesPageTemplate title={frontmatter.title} image={frontmatter.image} posts={posts} />
    </Layout>
  );
};

ActualitesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    markdownRemark: PropTypes.object,
  }),
};

export default ActualitesPage;

export const pageQuery = graphql`
  query ActualitesPage($id: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "actualite-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 235)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "D MMM YYYY", locale: "fr")
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
